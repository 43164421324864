<template>
  <div class="page">
    <div class="page-main">
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item :label="$t('edm024')">
          <el-input v-model="form.apiId"></el-input>
        </el-form-item>
        <el-form-item :label="$t('edm025')">
          <el-input v-model="form.apiKey"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getApi } from "@/api/apiService"
export default {
  name: 'ApiService',
  components: {
  },
  data() {
    return {
      form: {}
    }
  },
  computed: {

  },
  methods: {

  },
  mounted() {
    getApi().then((response) => {
      this.form = response.data.result.rows[0];
    })
  }
}
</script>

<style lang="scss" scoped>
.page-main {
  padding: 30px 0;
}
</style>
